
import { defineComponent, ref } from 'vue'
import PageAuth from '@/components/layout/PageAuth.vue'
import CodeConfirmation from '@/components/pages/auth/CodeConfirmation.vue'

export default defineComponent({
  components: {
    PageAuth,
    CodeConfirmation,
  },
  setup() {
    const confirmation = ref({
      code: '',
    })

    return {
      confirmation,
    }
  },
})
